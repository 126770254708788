/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './assets/emoji-panel/emoji-panel-apple-20.css';
@include mat.core();

$md-quorumdefault: (
  50: #e1ebf3,
  100: #b3cee1,
  200: #80adce,
  300: #4d8cba,
  400: #2773ab,
  500: #015a9c,
  600: #015294,
  700: #01488a,
  800: #013f80,
  900: #002e6e,
  A100: #9dbdff,
  A200: #6a9bff,
  A400: #3778ff,
  A700: #1e67ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white,
  ),
);

$md-quorumaccent: (
  50: #eef9e8,
  100: #d4efc5,
  200: #b8e59f,
  300: #9bdb79,
  400: #85d35c,
  500: #70cb3f,
  600: #68c639,
  700: #5dbe31,
  800: #53b829,
  900: #41ac1b,
  A100: #e9ffe2,
  A200: #c2ffaf,
  A400: #9bff7c,
  A700: #87ff63,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($md-quorumdefault, 700);
$my-accent: mat.define-palette($md-quorumaccent, 500, A100, A400);
$my-warn: mat.define-palette(mat.$red-palette, 600);

$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
  )
);

$my-custom-typography: mat.define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $display-4: mat.define-typography-level(112px, 112px, 300),
  $display-3: mat.define-typography-level(56px, 56px, 400),
  $display-2: mat.define-typography-level(45px, 48px, 400),
  $display-1: mat.define-typography-level(34px, 40px, 400),
  $headline: mat.define-typography-level(24px, 32px, 400),
  $title: mat.define-typography-level(20px, 32px, 500),
  $subheading-2: mat.define-typography-level(16px, 28px, 400),
  $subheading-1: mat.define-typography-level(15px, 24px, 400),
  $body-2: mat.define-typography-level(14px, 24px, 500),
  $body-1: mat.define-typography-level(14px, 20px, 400),
  $caption: mat.define-typography-level(12px, 20px, 400),
  $button: mat.define-typography-level(14px, 14px, 500),
  $input: mat.define-typography-level(16px, 1.125, 400),
);

@include mat.all-component-themes($my-theme);
@include mat.core($my-custom-typography);

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100vh;
  margin: 0;
  font-family: 'Roboto';
}

.mat-drawer-container {
  background-color: #fff !important;
}

.mat-form-field-empty {
  font-size: 14px !important;
}

img.emoji {
  height: 20px;
  vertical-align: middle;
  margin: -0.2ex 0.15em 0.2ex;
}

button {
  outline: none !important;
}

@layer base {
  a {
    @apply text-blue-900;
    @apply underline;
  }
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/OpenSans-VariableFont_wdth\,wght.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Catamaran';
  src: url('./assets/Catamaran-VariableFont_wght.ttf');
}
